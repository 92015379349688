//引入 axios
import { axios } from "../api/request";


//发送验证码
export function loginphone(data) {
    return axios.request({
      // url: "/Publics/check_version",
      url: "/api/Publics/sendSms",
      method: "POST",
      params: {
        ...data,
      },
    });
  }
  
//登录
export function login(data) {
    return axios.request({
      // url: "/Publics/check_version",
      url: "/api/kfadmin/code_login",
      method: "POST",
      params: {
        ...data,
      },
    });
  }




