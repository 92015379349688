import Vue from 'vue'
import App from './App.vue'
import router from "./router"  // 路由  
import ElementUI from 'element-ui';  //  Elm ui 
import 'element-ui/lib/theme-chalk/index.css';  
import md5 from 'js-md5';
import VueLoadmore from 'vuejs-loadmore';
Vue.config.productionTip = false

Vue.prototype.$md5=md5;

Vue.use(ElementUI);

Vue.use(VueLoadmore);
//全局注册组件
import LoginByname from "../src/components/LoginByname"
Vue.component("LoginByname",LoginByname)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')



//路由守卫

